@import url('https://fonts.googleapis.com/css?family=Oswald|Playfair+Display:400,700|Roboto:200,400,500|Sacramento&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Amatica+SC&subset=latin,latin-ext');
@import '~bootstrap/dist/css/bootstrap.css';
@import '~magnific-popup/dist/magnific-popup.css';
@import 'massive/shortcodes/shortcodes.css';
@import '../vendor/icomoon/style.css';
@import 'massive/style.css';
@import 'massive/style-responsive.css';
@import 'massive/default-theme.css';
@import 'massive/blog.css';
